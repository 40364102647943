<template>
  <section id="instructor-view-course" class="backend">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 text-center">
          <h1 class="page-heading">View Course</h1>
          <h3 v-if="$store.state.viewingCourse.date">Class Date {{ $store.state.viewingCourse.date }} From {{ $store.state.viewingCourse.start }} to {{ $store.state.viewingCourse.end }}</h3>
        </div>
      </div>
    </div>
    
    <dcLoading v-if="loading"></dcLoading>

    <div class="container" v-else-if="!loading">
      <div class="row">
        <div class="col-sm-12" :class="{ 'col-md-6': currentInstructor, 'col-md-8 col-md-offset-2': !currentInstructor }">
          <!-- <dcCoursePreview
            :course="coursePreview"
          ></dcCoursePreview> -->
          <div class="well">
            <div class="flex flex-row">
              <h2 class="well-heading mb0">Course Details</h2>
            </div>
            <div class="well-content">
              <div v-for="(value, key, index) in coursePreviewFields" :key="index">
                <div v-if="key === 'classes'">
                  <div v-for="(classDate, index) in course.classes" :key="classDate.id" class="well-item" >
                    <p><strong>Class {{ index + 1 }}</strong></p>
                    <p><a @click.prevent="selectClassDate(classDate)" href="#">{{ new Date(classDate.start).toLocaleDateString() }} From {{ new Date(classDate.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }) }} to {{ new Date(classDate.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }) }}</a></p>
                  </div>
                </div>

                <div v-else-if="key === 'description'" class="well-item">
                  <p><strong>{{ value }}</strong>:</p>
                  <span v-html="coursePreview[key]"></span>
                </div>

                <p v-else class="well-item"><strong>{{ value }}</strong>: {{ coursePreview[key] }}</p>

              </div>
            </div>
          </div>
        </div>
        <div v-if="currentInstructor" class="col-sm-12 col-md-6">
          <div class="well p0">
            <div class="well-header flex flex-row">
              <h2 class="well-heading mb0">Course Roster</h2>
              <div class="flex flex-row btn-container">
                <button :disabled="!currentInstructor" @click="emitEvent('print')" class="btn btn-gray mr-10">
                  <i class="fa fa-circle-o-notch fa-spin fa-fw" v-if="printing"></i>
                  Print
                </button>
                <button data-cy="viewAttendance" class="btn btn-dc" v-if="currentInstructor" :disabled="!studentsAttached" @click="emitEvent('link-click', { destination: 'drivecoach.instructor.dashboard.course.attendance', courseId: course.id })">Attendance</button>
              </div>
            </div>
            <div class="well-content grid-container" :style="columnStyles">
              <div class="grid-row header">
                <div v-if="showEmailForm" class="grid-item table-header">Select</div>
                <div class="grid-item table-header">Contract Recieved</div>
                <div class="grid-item table-header">Name</div>
                <div class="grid-item table-header">Actions</div>
              </div>
                
              <div class="grid-row" v-for="student in options.studentsInCourse" :key="student.id">
                <div v-if="showEmailForm" class="grid-item" style="justify-content:center;"><input style="margin-top: 0;" type="checkbox" v-model="selectedStudents" :value="student" /></div>
                <div class="grid-item">
                  <select v-model="student.pivot.received_contract" class="form-control">
                    <option v-for="option in contractOptions" :value="option.value">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div class="grid-item">{{ student.fullname }}</div>
                <div class="grid-item btn-container">
                  <button type="button" data-cy="drivecoach.admin.course.roster.save" @click="emitEvent('save-roster', {student_id: student.id, course_id: course.id, received_contract: student.pivot.received_contract })" class="btn btn-green btn-small">Save</button>
                  <button type="button" data-cy="remove-student" @click="emitEvent('remove-student', {id: student.id, fullname: student.fullname})" class="btn btn-red btn-small">Remove</button>
                </div>
              </div>
              <div class="grid-row" v-if="showEmailForm">
                <div class="grid-item" style="justify-content:center;"><input style="margin-top: 0;" type="checkbox" v-model="allStudentsSelected"/></div>
                <div class="grid-item" style="grid-column: 2 / 5;"><label for="" style="margin-bottom: 0; padding: 5px;">Email All Students</label></div>
              </div>
            </div>

            <div v-if="showEmailForm">
              <div class="well-header" style="margin-top:2em;">
                <h2 class="well-heading mb-1">Email Students</h2>
                <p>Use this to send emails to students in this course. <strong>Select students to email above.</strong></p>
              </div>
              <div class="well-content">
                <div style="padding: 1em;">
                  <dcSendEmail
                    :courseId="course.id"
                    :selectedUsers="selectedStudents"
                    :placeholder="'Type your message here! Send Zoom link, reminder to bring permits, etc.'"
                    @emit-event="emitEvent($event.eventName, $event.dataObject)"
                  ></dcSendEmail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'

import dcLoading from './../../../../common/directives/vue-directives/loading/Loading.vue'
import dcSendEmail from '../../../../common/directives/vue-directives/send-email/SendEmail.vue'

export default {
  name: 'dcInstructorViewCourse',
  components: { dcLoading, dcSendEmail },
  props: { 
    course: { type: Object, default: () => {} },
    currentUserId: { type: Number, required: true },
    studentsInCourse: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    printing: { type: Boolean, default: false }
  },
  data() {
    return {
      contractOptions: [{"name":"Yes","value":1}, {"name":"No","value":0}],
      selectedStudents: [],
      coursePreviewFields: {
        name: 'Course Name',
        type: 'Course Type',
        number_of_seats: 'Number of Students',
        location: 'Location', 
        instructors: 'Instructor(s)',
        classroom: 'Classroom',
        description: 'Course Description',
        classes: 'Class '
      },
    }
  },
  methods: {
    emitEvent: function(eventName, data) {
      this.$emit(eventName, data)
    },
    selectClassDate(classDate){
      let data = {
        id: classDate.id,
      }
      let course = {
        id: this.course.id,
        class_id: classDate.id,
        instructor: classDate.instructor ? classDate.instructor : { 'fullname' : 'None' },
        instructor_id: classDate.instructor_id,
        date: new Date(classDate.start).toLocaleDateString(),
        start: new Date(classDate.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
        end: new Date(classDate.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
      }
      this.$store.dispatch('SET_VIEW_COURSE', course);
      this.emitEvent('set-course-class-date', data);
    }
  },
  computed: {
    allStudentsSelected: {
      get() {
        if (this.studentsInCourse && this.studentsInCourse.length > 0) { // A users array exists with at least one item
          let allSelected = true;
          for (const student of this.studentsInCourse) {
            if (!this.selectedStudents.includes(student)) {
              allSelected = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if(!allSelected) break;
          }
          return allSelected;
        }
        return false;
      },
      set(value) {
        const selected = [];
        this.studentsInCourse.forEach((student) => {
          if(value === true) {
            selected.push(student);
          }
        });
        this.selectedStudents = selected;
      }
    },
    options: function() {
      return {
        studentsInCourse: this.studentsInCourse.sort((a, b) => a.lastname.localeCompare(b.lastname))
      }
    },
    coursePreview: function() {
      let course = this.course;
      return {
        id: course.id,
        name: course.name,
        type: course.type.name,
        number_of_seats: course.number_of_seats,
        location: course.location.name, //location and classroom are both index based so this just auto selects the first option
        instructors: course.classes.map(classDate => {
          return classDate.instructor ? classDate.instructor.fullname : 'None';
        }).join(", "),
        classroom: course.classroom.name,
        description: course.description,
        classes: this.previewClasses
      }
    },
    previewClasses: function() {
      return this.course.classes.map((classTime) => {
        return {
          date: moment(classTime.start).format('M / D / YYYY'),
          startTime: moment(classTime.start).format('hh:mm A'),
          endTime: moment(classTime.end).format('hh:mm A'),
          instructor_id: classTime.instructor_id,
          instructor: classTime.instructor,
        }
      })
    },
    currentInstructor: function() {
      return this.$store.state.viewingCourse.instructor_id === this.currentUserId;
    },
    studentsAttached: function() {
      return this.studentsInCourse.length > 0
    },
    showEmailForm: function() {
      return false; //this is just to hide it for now
    },
    columnStyles: function() {
      if(this.showEmailForm === false) {
        return {
          'grid-template-columns': '100px 1fr 1fr'
        }
      } 
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../../../assets/css/partials/variables.scss';
  @import '../../../../../assets/css/partials/grid.scss';
  
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &.btn-container {
      justify-content: flex-end;
    }
  }
  
  .well {
    border-radius: 8px;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #F4F4F4;
    padding: 15px;
    width: 100%;
    margin-bottom: 20px;

    h2 {
      font-size: 140%;
      font-weight: 300;
      text-align: left;
    }
    .well-header {
      padding: 15px;
    }
    &.p0 {
      padding: 0;
    }
    .well-item {
      &.product {
        padding: 10px 15px;
        border-top: 2px solid #E1E7EB;
      }
    }
  }

</style>
