"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minutes_list = void 0;
exports.minutes_list = [
    { 'n': '0 Minutes', 'v': '00' },
    { 'n': '5 Minutes', 'v': '5' },
    { 'n': '10 Minutes', 'v': '10' },
    { 'n': '15 Minutes', 'v': '15' },
    { 'n': '20 Minutes', 'v': '20' },
    { 'n': '25 Minutes', 'v': '25' },
    { 'n': '30 Minutes', 'v': '30' },
    { 'n': '35 Minutes', 'v': '35' },
    { 'n': '40 Minutes', 'v': '40' },
    { 'n': '45 Minutes', 'v': '45' },
    { 'n': '50 Minutes', 'v': '50' },
    { 'n': '55 Minutes', 'v': '55' },
];
