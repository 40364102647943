"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InstructorController = /** @class */ (function () {
    function InstructorController($rootScope, currentUser, store, AUTH_EVENTS, policies) {
        this.user = currentUser;
        this.school = policies.school;
        this.dsConfig = {
            'nav': {
                'use_team_calendar': false,
                'instructor_can_create_lessons': false,
                'instructor_can_create_orders': false,
            }
        };
        var DS_INSTRUCTOR_NAV_CONFIG = 'ds_instructor_nav_config';
        $rootScope.$on(AUTH_EVENTS.logoutSuccess, function () {
            store.remove(DS_INSTRUCTOR_NAV_CONFIG);
        });
        try {
            this.dsConfig.nav.use_team_calendar = policies.policies.getPolicyByName('calendar').getMetaValueByKey('use_team_calendar');
        }
        catch (Error) {
            this.dsConfig.nav.use_team_calendar = false;
        }
        try {
            this.dsConfig.nav.instructor_can_create_lessons = policies.policies.getPolicyByName('drives').getMetaValueByKey('instructor_can_create_lessons');
        }
        catch (Error) {
            this.dsConfig.nav.instructor_can_create_lessons = false;
        }
        try {
            this.dsConfig.nav.instructor_can_create_orders = policies.policies.getPolicyByName('billing').getMetaValueByKey('instructor_can_create_orders');
        }
        catch (Error) {
            this.dsConfig.nav.instructor_can_create_orders = false;
        }
    }
    return InstructorController;
}());
InstructorController.$inject = ['$rootScope', 'currentUser', 'store', 'AUTH_EVENTS', 'policies'];
exports.default = InstructorController;
