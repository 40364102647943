"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var VehiclesModel = /** @class */ (function () {
    function VehiclesModel($http, APPURL) {
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/vehicles',
            UPDATE: APPURL + '/api/v1/vehicles',
            CREATE: APPURL + '/api/v1/vehicles'
        };
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
        this.cacheResult = this.cacheResult.bind(this);
    }
    VehiclesModel.prototype.extract = function (result) {
        return result.data;
    };
    VehiclesModel.prototype.cacheResults = function (result) {
        return this.extract(result);
    };
    VehiclesModel.prototype.cacheResult = function (result) {
        return this.extract(result);
    };
    VehiclesModel.prototype.getVehiclesByLocation = function (location) {
        var location_id;
        if (typeof location === 'object') {
            location_id = location.id;
        }
        else {
            location_id = location;
        }
        return this.$http.get(this.URLS.FETCH + '/location/' + location_id).then(this.cacheResult);
    };
    ;
    VehiclesModel.prototype.getVehicleByID = function (id) {
        return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheResult);
    };
    ;
    VehiclesModel.prototype.getVehicles = function (data) {
        if (data && data.hasOwnProperty('all') && data.all === true) {
            return this.$http.get(this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        if (data && (data.hasOwnProperty('page') ||
            data.hasOwnProperty('name') ||
            data.hasOwnProperty('status'))) {
            return this.$http.get(this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    ;
    VehiclesModel.prototype.searchVehicles = function (data, drive, event) {
        return this.$http.post(this.URLS.FETCH + '/search', data).then(this.cacheResults);
    };
    ;
    VehiclesModel.prototype.createVehicle = function (vehicle) {
        return this.$http.post(this.URLS.CREATE, vehicle).then(this.cacheResults);
    };
    ;
    VehiclesModel.prototype.editVehicle = function (vehicle) {
        return this.$http.put(this.URLS.CREATE + '/' + vehicle.id, vehicle).then(this.cacheResults);
    };
    return VehiclesModel;
}());
VehiclesModel.$inject = ['$http', 'APPURL'];
exports.default = VehiclesModel;
