import moment from 'moment'
import isNil from 'lodash/isNil'
import { isJson } from '../../../common/helpers/json';

function AdminEventsCreateController($scope, $state, UsersModel, UserService, EventsModel, FlashService, ClassroomsModel, VehiclesModel, BatchCreateService, campuses, PoliciesModel) {

    let vm = this;
    vm.locations = campuses;
    vm.overrideInstructorSettings = 'N';
    vm.user = UserService.getCurrentUser();
    vm.instructors = [];
    vm.formData = {
        'school_id': vm.user.school_id,
        'duration': {}
    };

    let watchForAvailabilityParams = [
        'vm.formData.date',
        'vm.formData.time',
        'vm.formData.duration.hours',
        'vm.formData.duration.minutes'
    ];

    vm.getInstructors = getInstructors;
    vm.batchCreate = batchCreate;
    vm.getVehicles = getVehicles;
    vm.getClassrooms = getClassrooms;
    vm.updateForm = updateForm;
    vm.clearVariableData = clearVariableData;
    vm.clearField = clearField;
    vm.submit = submit;
    vm.updateTime = updateTime;
    vm.updateDate = updateDate;

    init();

    function batchCreate() {
        BatchCreateService.setEvent(vm.formData);
        $state.go('drivecoach.admin.events.batch');
    }

    function watch() {
        $scope.$watchGroup(watchForAvailabilityParams, function (newValues, oldValues) {
            if (newValues != oldValues) {
                clearVariableData();
                updateForm();
            }
        });
    }

    function init() {
        UsersModel.getInstructors({'all' : true, 'status' : 'Active'}).then(response => {
            vm.instructors = [{'id' : 0, 'fullname' : 'None'}].concat(response.instructors);
        });
        PoliciesModel.getAll().then(response => {
            vm.school = vm.user.school;
            vm.policies = {};

            response.policies.forEach(policy => {
                let policyName = policy.name;
                vm.policies[policyName] = {'id': policy.id};
                let policyMeta = {};
                policy.policymeta.forEach(meta => {
                    policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
                });
                vm.policies[policyName].policymeta = policyMeta;
            });

            if (vm.policies.events.policymeta.date === 'today') {
                vm.formData.date = new Date();
            }

            if (vm.policies.events.policymeta.date === '+3 days') {
                vm.formData.date = moment().add('3', 'days').toDate();
            }

            if (vm.policies.events.policymeta.date === '+7 days') {
                vm.formData.date = moment().add('7', 'days').toDate();
            }

            vm.formData.time = moment(vm.policies.events.policymeta.time, 'H:mm').toDate();

            if (vm.policies.events.policymeta.minimum_session_length) {
                let _durMoment = moment.duration(vm.policies.events.policymeta.minimum_session_length, 'minutes').format('H:mm').split(':');
                vm.formData.duration.hours = _durMoment[0];
                vm.formData.duration.minutes = _durMoment[1];
            }

            watch();

        });
    }

    function getVehicles(name, all) {

        if(!vm.formData.date || !vm.formData.time) {
            return;
        }

        let params = {
            'location': vm.formData.location
        };

        if (false === all) {
            params.date = {
                'date': moment(vm.formData.date).format('YYYY-MM-DD'),
                'time': moment(vm.formData.time).format('HH:mm:ss'),
                'duration': ( parseInt(vm.formData.duration.hours) * 60 ) + parseInt(vm.formData.duration.minutes)
            }
        }

        if (name) {
            params.name = name;
        }

        VehiclesModel.searchVehicles(params).then(function (response) {
            vm.vehicles = response.vehicles;
        });

    }

    function getInstructors(name, all) {

        if(!vm.formData.date || !vm.formData.time) {
            return;
        }

        let params = {
            'location': vm.formData.location,
            'overrideInstructorSettings': vm.overrideInstructorSettings
        };

        if (false === all) {
            params.date = {
                'date': moment(vm.formData.date).format('YYYY-MM-DD'),
                'time': moment(vm.formData.time).format('HH:mm:ss'),
                'duration': ( parseInt(vm.formData.duration.hours) * 60 ) + parseInt(vm.formData.duration.minutes)
            }
        }

        if (!isNil(vm.formData.date) && !isNil(vm.formData.time)) {
            params.date = {
                'date': moment(vm.formData.date).format('YYYY-MM-DD'),
                'time': moment(vm.formData.time).format('HH:mm:ss'),
                'duration': ( parseInt(vm.formData.duration.hours) * 60 ) + parseInt(vm.formData.duration.minutes)
            }
        }

        if (name) {
            params.name = name;
        }

        UsersModel.searchInstructors(params).then(function (response) {
            vm.instructors = response.instructors;
        });

    }

    function getClassrooms(name, all) {

        if(!vm.formData.date || !vm.formData.time) {
            return;
        }

        let params = {
            'location': vm.formData.location
        };

        if (false === all) {
            params.date = {
                'date': moment(vm.formData.date).format('YYYY-MM-DD'),
                'time': moment(vm.formData.time).format('HH:mm:ss'),
                'duration': ( parseInt(vm.formData.duration.hours) * 60 ) + parseInt(vm.formData.duration.minutes)
            }
        }

        if (name) {
            params.name = name;
        }

        ClassroomsModel.search(params).then(function (response) {
            vm.classrooms = response.classrooms;
        });
    }

    function updateForm() {
        getClassrooms(null, false);
        getVehicles(null, false);
    }

    function clearVariableData() {
        vm.formData.vehicle = null;
        vm.formData.classroom = null;
    }

    function clearField(field) {
        vm.event[field] = null;
    }

    function submit(isValid) {
        const payload = Object.assign({}, vm.formData)
        vm.submitted = true;

        if (isValid) {
            payload.formattedDate = moment(payload.date).format('MM/DD/YYYY');
            payload.formattedTime = moment(payload.time).format('HH:mm:ss');
            if (payload.duration.hours === "0") {
                payload.duration.hours = 0;
            } else {
                payload.duration.hours = parseInt(payload.duration.hours);
            }
            if (payload.duration.minutes === "00") {
                payload.duration.minutes = 0;
            } else {
                payload.duration.minutes = parseInt(payload.duration.minutes);
            }

            if (payload.instructor.id === 0) {
                delete payload.instructor;
            }

            EventsModel.create(payload).then(response => {
                FlashService.setMessage({'type': 'success', 'message': response.message});
                vm.submitted = false;
                $state.go('drivecoach.admin.calendar', {}, {'reload': true});
            });
        } else {
            FlashService.setMessage({'type': 'error', 'message': 'Please fill in the form.'});
        }
    }

    function updateDate(newDate) {
      vm.formData.date = newDate.selected[0]
    }

    function updateTime(newTime) {
      vm.formData.time = newTime.selected[0]
    }

}
AdminEventsCreateController.$inject = ['$scope', '$state', 'UsersModel', 'UserService', 'EventsModel', 'FlashService', 'ClassroomsModel', 'VehiclesModel', 'BatchCreateService', 'campuses', 'PoliciesModel'];

export default AdminEventsCreateController;
