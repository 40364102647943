import moment from 'moment'
import { getIcon, getReportcardStatusDisplay } from '../../models/reportcards-model';
import {isJson} from '../../../common/helpers/json'

function dcReportcardController($scope, UserService){

    let vm = this;
    vm.currentUser = UserService.getCurrentUser();

    init();

    $scope.$watch('vm.reportcard', function(){
        init();
    });

    function init(){
        if (typeof vm.reportcard !== 'undefined') {
            vm.reportcard.grades = vm.reportcard.grades.map(grade => {
                grade.icon = getIcon(grade.task.name);
                grade.statusDisplay = getReportcardStatusDisplay(grade.status);
                return grade;
            });

            let driveMoment = moment(vm.reportcard.drive.drive_date + ' ' + vm.reportcard.drive.start_time, 'YYYY-MM-DD H:mm a' );
            vm.drivedate = driveMoment.format('dddd, MMMM Do YYYY');
            vm.reportcard.drive.start_time = driveMoment.format('h:mm a');
            vm.reportcard.drive.end_time = driveMoment.add(vm.reportcard.drive.session_length, 'minutes').format('h:mm a');
            if (vm.reportcard.drive.instructor) {
                vm.reportcard.drive.instructor.usermeta.forEach(meta => {
                    vm.reportcard.drive.instructor[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
                });
            }
            if (vm.reportcard.drive.observation === true) {
                vm.reportcard.drive.type.name = vm.reportcard.drive.type.name + ' (Observation)';
            }
        }
    }
}
dcReportcardController.$inject = ['$scope','UserService'];
export default dcReportcardController;
