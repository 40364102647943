"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIcon = getIcon;
exports.getReportcardStatusDisplay = getReportcardStatusDisplay;
var queryString_1 = require("../helpers/queryString");
var ReportcardsModel = /** @class */ (function () {
    function ReportcardsModel($http, APPURL) {
        var _this = this;
        this.getReportcardByID = function (id) {
            return _this.$http.get(_this.URLS.FETCH + '/' + id).then(_this.cacheResults);
        };
        this.get = function (data) {
            if (data === void 0) { data = null; }
            if (data != null) {
                return _this.$http.get(_this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(_this.cacheResults);
            }
            return _this.$http.get(_this.URLS.FETCH).then(_this.cacheResults);
        };
        this.update = function (request) {
            return _this.$http.put(_this.URLS.FETCH + '/' + request.id, request).then(_this.cacheResults);
        };
        this.create = function (request) {
            return _this.$http.post(_this.URLS.FETCH, request).then(_this.cacheResults);
        };
        this.submitGrades = function (data) {
            return _this.$http.post(_this.URLS.FETCH + '/submit-grades', data).then(_this.cacheResults);
        };
        this.submitPassFailGrade = function (data) {
            var payload = { 'grade': data.grade, 'drive_id': data.drive_id, 'reportcard_id': data.reportcard_id };
            return _this.$http.post(_this.URLS.FETCH + '/submit-grades', payload).then(_this.cacheResults);
        };
        this.submitGenericGrade = function (data) {
            return _this.$http.post(_this.URLS.FETCH + '/submit-grades', data).then(_this.cacheResults);
        };
        this.unlockReportCard = function (reportcard_id) {
            return _this.$http.put(_this.URLS.FETCH + '/' + reportcard_id + '/unlock', {}).then(_this.cacheResults);
        };
        this.destroy = function (card) {
            return _this.$http.delete(_this.URLS.FETCH + '/' + card.id).then(_this.cacheResults);
        };
        this.destroyCollection = function (reportcards) {
            return _this.$http.post(_this.URLS.FETCH + '/delete-batch', reportcards).then(_this.cacheResults);
        };
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/reportcards',
            UPDATE: APPURL + '/api/v1/reportcards',
            CREATE: APPURL + '/api/v1/reportcards'
        };
        this.data = [];
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    ReportcardsModel.prototype.extract = function (result) {
        return result.data;
    };
    ReportcardsModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    return ReportcardsModel;
}());
function getIcon(name) {
    var iconName = null;
    switch (name) {
        case 'Acceleration':
            iconName = 'acceleration';
            break;
        case 'Blind Spots':
            iconName = 'blind_spots';
            break;
        case 'Braking':
            iconName = 'braking';
            break;
        case 'Changing Lanes':
            iconName = 'changing_lanes';
            break;
        case 'Following Distance':
            iconName = 'following_distance';
            break;
        case 'In town - IT':
            iconName = 'in_town';
            break;
        case 'Interstate - I':
            iconName = 'interstate';
            break;
        case 'Left Turns':
            iconName = 'left_turns';
            break;
        case 'Night Driving - N':
            iconName = 'observation';
            break;
        case 'Parallel Parking - P':
            iconName = 'parallel_parking';
            break;
        case 'General Parking':
            iconName = 'parking';
            break;
        case 'Residential - R':
            iconName = 'residential';
            break;
        case 'Right Turns':
            iconName = 'right_turns';
            break;
        case 'Straight Line Reverse':
            iconName = 'straight_line_reverse';
            break;
        case 'Turn About':
            iconName = 'turn_about';
            break;
        default:
            name.replace(' ', '_').replace('-', '_').toLowerCase();
    }
    return iconName;
}
function getReportcardStatusDisplay(status) {
    var result = status;
    if (status === 'green') {
        return 'Awesome job!';
    }
    if (status === 'yellow') {
        return 'Good, but not great!';
    }
    if (status === 'red') {
        return 'Needs work, keep practicing!';
    }
    if (status === 'grey') {
        return 'Not Covered';
    }
    return result;
}
ReportcardsModel.$inject = ['$http', 'APPURL'];
exports.default = ReportcardsModel;
