import { Order } from '../../../../../classes/Order';
import { Product } from '../../../../../classes/Product';
import { Coupon } from '../../../../../classes/Coupon';
import { cashierOrderRequestTransformer } from '../../../../../classes/Cashier';

class AdminStudentCreateOrderController {

    constructor($state, $stateParams, UsersModel, FlashService, OrdersModel, ProductsModel, CouponsModel, CreateOrderService) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.OrdersModel = OrdersModel;
        this.CouponsModel = CouponsModel;
        this.FlashService = FlashService;

        this.CreateOrderService = CreateOrderService;

        this.product = null;
        this.products = [];
        this.$cashier = CreateOrderService.cashier();
        this.$order = this.$cashier.order();
        this.student = this.$cashier.order().student();
        this.$cashier.$addresses = [];
        this.$cashier.$paymentMethods = [];
        CreateOrderService.updateBillingAddressesList();
        CreateOrderService.updatePaymentMethodsList();

        this.couponConfig = {
            loading: true,
            coupon: {},
            showNewCouponForm: false,
            datepicker: {
                open: ($event) => {
                    this.couponConfig.datepicker.status.opened = true;
                },
                dateOptions: {
                    formatYear: 'yy',
                    startingDay: 7,
                    showWeeks: false,
                    minDate: new Date()
                },
                format: 'dd-MMMM-yyyy',
                status: {
                    opened: false
                }
            },
            datepicker2: {
                open: ($event) => {
                    this.couponConfig.datepicker2.status.opened = true;
                },
                dateOptions: {
                    formatYear: 'yy',
                    startingDay: 7,
                    showWeeks: false,
                    minDate: () => this.couponConfig.coupon.start
                },
                format: 'dd-MMMM-yyyy',
                status: {
                    opened: false
                }
            }
        };

        this.CouponsModel.get({'paging' : "0", "valid" : 1}).then(response => {
            const coupons = response.coupons.map(data => new Coupon(data))
            this.coupons = coupons;
            this.couponConfig.loading = false;
        })
        ProductsModel.get({ 'paging': false, 'disabled': false }).then(response => {
            this.products = response.products.data.map(product => new Product(product));
            this.loading = false;
            this.submitting = false;
        })

        this.createOrder = this.createOrder.bind(this);
    }

    applyCoupon(){
        this.$cashier.addCoupon(this.selectedCoupon);
        this.$cashier.calculateTotal();
        if (this.$cashier.order().payments().length > 0){
            this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
        }
        delete this.selectedCoupon;
    }

    removeCoupon(){
        this.$cashier.removeCoupon();
        this.$cashier.calculateTotal();
        if (this.$cashier.order().payments().length > 0){
            this.$cashier.order().payments[0].setAmount(this.$cashier.totalDue());
        }
    }

    createOrder(order) {
        let data = cashierOrderRequestTransformer(this.$cashier)
        this.OrdersModel.create(data).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            })
            this.reset()
            if (response.student_activated === true) {
                this.$state.go('drivecoach.admin.students.view', { 'userId': this.$stateParams.userId }, { reload: true });
            } else {
                this.$state.go('drivecoach.admin.students.verification');
            }
        }).catch(Error => {
        })
    }

    reset() {
        this.order = new Order;
        this.product = null;
        delete this.selectedCoupon;
        this.loading = false;
        this.submitting = false;
    }

    addProductToOrder() {
        this.$cashier.addProduct(this.product);

        let totalWithoutSurcharge = this.$cashier.totalDue()
        //set initial value for "amount paid today" field
        this.$cashier.order().payments()[0].setAmount(totalWithoutSurcharge);

        this.$cashier.calculateTotal()
    }

    createCoupon(){
        this.couponConfig.loading = true;
        let coupon = Object.assign({}, this.couponConfig.coupon)
        if (coupon.type === 'fixed') {
            coupon.amount = coupon.amount * 100;
        }
        this.CouponsModel.create(coupon).then(response => {
            this.couponConfig.loading = false;
            const newCoupon = new Coupon(response.coupon)
            this.coupons.push(newCoupon)
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            })
            this.couponConfig.coupon = {}
            this.selectedCoupon = newCoupon;
            this.applyCoupon();
        })
    }

}
AdminStudentCreateOrderController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'OrdersModel', 'ProductsModel', 'CouponsModel', 'CreateOrderService'];
export default AdminStudentCreateOrderController;
