import { isJson } from "../../../common/helpers/json";

function AdminSettingsNotificationsController($state, UserService, FlashService, PoliciesModel, EmailTemplates) {

    const vm = this;
    vm.loaded = false;
    vm.currentUser = UserService.getCurrentUser();
    vm.submitForm = submitForm;
    vm.handleStudentNotificationChange = handleStudentNotificationChange.bind(this);
    vm.handleInstructorNotificationChange = handleInstructorNotificationChange.bind(this);
    vm.templates = [];
    vm.policies = {
        'notifications' : {
            'policymeta' : {}
        }
    };

    init();

    function handleStudentNotificationChange(data) {
        vm.policies.notifications.policymeta.globalstudentnotification = data.html;
    }

    function handleInstructorNotificationChange(data) {
        vm.policies.notifications.policymeta.globalinstructornotification = data.html;
    }

    function init() {
        EmailTemplates.get().then(response => {
            vm.templates = response;
        })

        PoliciesModel.getAll().then(function (response) {
            response.policies.forEach(function (policy) {
                let policyName = policy.name;
                let policyMeta = {};
                vm.policies[policyName] = {'id': policy.id};
                policy.policymeta.forEach(function (meta) {
                    policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
                });
                vm.policies[policyName].policymeta = policyMeta;
            });
            vm.policies.notifications.policymeta.id = vm.policies.notifications.id;
            if (!vm.policies.notifications.policymeta.globalstudentnotification) {
                vm.policies.notifications.policymeta.globalstudentnotification = '';
            }
            if (!vm.policies.notifications.policymeta.globalinstructornotification) {
                vm.policies.notifications.policymeta.globalinstructornotification = '';
            }
            vm.loaded = true;
        });
    }

    function submitForm(isValid) {

        if (isValid) {
            PoliciesModel.update(vm.policies.notifications.policymeta).then(function (response) {
                FlashService.setMessage({'type': 'success', 'message': response.message});
            });
        } else {
            FlashService.setMessage({'type': 'error', 'message': 'All fields are required.'});
        }
    }

}
AdminSettingsNotificationsController.$inject = ['$state', 'UserService', 'FlashService', 'PoliciesModel', 'EmailTemplates'];
export default AdminSettingsNotificationsController;
