"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Address_1 = require("../../classes/Address");
var json_1 = require("../../common/helpers/json");
var CreateOrderService = /** @class */ (function () {
    function CreateOrderService(UsersModel) {
        this.UsersModel = UsersModel;
        this.$cashier = null;
        this.$order = null;
    }
    /**
     * Return and optionally set the Order
     * @param key
     * @param value
     */
    CreateOrderService.prototype.order = function (order) {
        if (order === void 0) { order = null; }
        if (order !== null) {
            this.cashier().setOrder(order);
        }
        return this.cashier().order();
    };
    /**
     * Return and optionally set the Cashier
     */
    CreateOrderService.prototype.cashier = function (cashier) {
        if (cashier === void 0) { cashier = null; }
        if (cashier !== null) {
            this.$cashier = cashier;
        }
        return this.$cashier;
    };
    CreateOrderService.prototype.updateBillingAddressesList = function () {
        var _this = this;
        if (!this.$cashier.order().student()) {
            return;
        }
        this.UsersModel.getPickupLocations(this.$cashier.order().student().id()).then(function (response) {
            var addresses = response.pickuplocations.map(function (location) {
                /** @ts-ignore */
                location.address = (0, json_1.isJson)(location.address) ? JSON.parse(location.address) : location.address;
                var _a = location.address, street = _a.street, city = _a.city, state = _a.state, zip = _a.zip, lat = _a.lat, lng = _a.lng;
                var $address = new Address_1.Address(location.id, location.name, street, city, state, zip, lat, lng);
                return $address;
            });
            _this.setStudentAddresses(addresses);
        });
    };
    CreateOrderService.prototype.updatePaymentMethodsList = function () {
        var _this = this;
        if (!this.$cashier.order().student()) {
            return;
        }
        this.UsersModel.getStudentPaymentMethods(this.$cashier.order().student().id()).then(function (response) {
            var paymentMethods = response.payment_methods;
            _this.setStudentPaymentMethods(paymentMethods);
        });
    };
    CreateOrderService.prototype.setStudentAddresses = function (addresses) {
        this.$cashier.setStudentAddresses(addresses);
    };
    CreateOrderService.prototype.setStudentPaymentMethods = function (paymentMethod) {
        this.$cashier.setStudentPaymentMethods(paymentMethod);
    };
    return CreateOrderService;
}());
CreateOrderService.$inject = ['UsersModel'];
exports.default = CreateOrderService;
