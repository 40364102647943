"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var states_1 = require("../../../../common/constants/states");
var AdminSchoolEditController = /** @class */ (function () {
    function AdminSchoolEditController($state, $stateParams, SchoolsModel, FlashService, TIMEZONES) {
        var _this = this;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SchoolsModel = SchoolsModel;
        this.FlashService = FlashService;
        this.TIMEZONES = TIMEZONES;
        this.states = states_1.States;
        this.statuses = [{ 'name': 'Active', 'value': 'ACTIVE' }, { 'name': 'Staging', 'value': 'STAGING' }];
        // this will be student info pulled from the server later
        SchoolsModel.getSchoolByID($stateParams.schoolId).then(function (response) {
            _this.school = response;
            _this.timezoneOptions = TIMEZONES.map(function (timezone) {
                if (timezone.code === _this.school.timezone) {
                    _this.school.timezone = timezone.code;
                }
                return timezone;
            });
        });
    }
    AdminSchoolEditController.prototype.submit = function () {
        var _this = this;
        var school = Object.assign({}, this.school);
        var formData = {
            id: school.id,
            name: school.name,
            code: school.code,
            phone: school.phone,
            email: school.email,
            status: school.status,
            timezone: school.timezone,
            address: {
                street: school.address.street,
                city: school.address.city,
                state: school.address.state,
                zip: school.address.zip,
            }
        };
        this.SchoolsModel.editSchool(formData).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.$state.go('drivecoach.admin.schools.view', { 'schoolId': _this.school.id }, { 'reload': true });
        });
    };
    return AdminSchoolEditController;
}());
AdminSchoolEditController.$inject = ['$state', '$stateParams', 'SchoolsModel', 'FlashService', 'TIMEZONES'];
exports.default = AdminSchoolEditController;
